<template>
  <v-container class="mx-lg-6 mt-7">
    <v-row>
      <v-spacer/>
      <v-col sm="11" xl="9">
    <p>
      Über das folgende <strong>barrierearme Bestellformular</strong> können Sie Vermessungsunterlagen <strong>ohne
      Nutzung der Karte</strong>
      - mit alleiniger Nutzung der Tastatur - beantragen, indem Sie das gewünschte Antragsgebiet über eine
      Koordinatenliste festlegen.</p>

    <p>
      Nach dem Bestätigen des Formulars über die Schaltfläche <strong>Portal-Anwendung öffnen</strong>> öffnet sich die
      Portalanwendung und Sie
      können den Antrag ggf. vervollständigen und endgültig absenden.
    </p>

    <v-row>
      <v-col class="ml-4">
        <v-radio-group
            v-model="usergroup"
            dense
            mandatory
            label="Nutzerkreis"
        >
          <v-radio
              :label="visibility.get('opendata')"
              value="opendata"
          ></v-radio>
          <v-radio
              :label="visibility.get('vermessungsstellen')"
              value="vermessungsstellen"
          ></v-radio>
        </v-radio-group>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="ml-8">
        <v-form ref="vup" target="_blank" action="/ASWeb/ASC_URL/GISConnectorRIA.do"
                method="post">
          <v-row v-for="(formField,i) in bestellformular" :key="i">
            <template v-if="formField.model==='gp_gc_session_auswahlpolygon'">
              <v-col cols="12" :class="usergroup==='vermessungsstellen'?'mt-4':''">
             Das Bestellpolygon kann im Format GeoJSON angegeben werden. Erster und letzter Punkt müssen identisch sein, z.B.<br/>
              <i>{"type": "polygon","koordinaten": [[[334512,5646955],[334508,5647046],[334622,5647017],[334512,5646955]]]}</i><br/>
              </v-col>
              </template>
            <template v-if="formField.usergroup===usergroup || formField.usergroup==='allgemein'">
              <v-col :cols="formField.cols||6" :class="`pa-0 ${formField.class}`">
                <v-text-field dense v-if="formField.type=='text-field'"
                              :label="formField.label"
                              :name="formField.model"
                              :type="formField.inputtype||'text'"
                              class="backgroundgreen"
                              hide-details
                ></v-text-field>
                <v-textarea dense auto-grow rows="1" v-else-if="formField.type=='textarea'"
                            :label="formField.label"
                            :name="formField.model"
                            :value="formField.value"
                            class="backgroundgreen"
                            hide-details
                >{{ formField.value }}
                </v-textarea>
              </v-col>
            </template>
          </v-row>
          <v-text-field v-show="false" value="VermUnterlagenPortal"
                        name="PROJECT"
                        type="text"/>
          <v-text-field v-show="false" value="Bestellklient"
                        name="APPLNAME"
                        type="text"/>
          <v-text-field v-if="usergroup==='opendata'" v-show="false" value="gast"
                        name="USER"
                        type="text"/>
          <v-text-field v-if="usergroup==='opendata'" v-show="false" value="gast"
                        name="PWD"
                        type="text"/>
          <v-row>

            <v-btn color="green" height="30" dark class="mt-4"
                   type="submit">
              <v-icon
                  left
              >mdi-cube-outline
              </v-icon>
              <span style="font-size: larger">Portal-Anwendung öffnen</span>
            </v-btn>
          </v-row>
        </v-form>
      </v-col>
    </v-row></v-col>
      <v-spacer/>
    </v-row>
  </v-container>
</template>

<script>
import {bestellformular, visibility} from '@/config';

export default {

  components: {},
  data() {
    return {
      bestellformular,
      visibility,
      usergroup: '',

    }
  },
  methods: {},

  mounted() {

  }
  ,


}
</script>
<style scoped>

.backgroundgreen{
  background-color: rgb(222, 239, 183);
}


</style>
